import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import {
  FaPhone,
  FaFacebookF,
  FaFacebookMessenger,
  FaInstagram,
  FaLine,
  FaFileAlt,
} from "react-icons/fa"

const NewsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          author
          mobile
          facebook
          instagram
          line
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title={`${data.site.siteMetadata.author} | เกี่ยวกับเรา`} />
      <div className="container text-center mt-5 mb-5">
        <h1>วีดีโอ</h1>
        <div className="line-center "></div>
        <div className="row">
            <div className="col-12 col-md-6 embed-responsive embed-responsive-1by1" style={{height: 550}}>
                <iframe className="embed-responsive-item" src="https://web.facebook.com/plugins/video.php?href=https%3A%2F%2Fweb.facebook.com%2Fseenfacialspa%2Fvideos%2F970339740080367%2F&show_text=1&width=560"  scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>
            </div>
            <div className="col-12 col-md-6 embed-responsive embed-responsive-1by1" style={{height: 550}}>
                <iframe className="embed-responsive-item" src="https://web.facebook.com/plugins/video.php?href=https%3A%2F%2Fweb.facebook.com%2Fseenfacialspa%2Fvideos%2F1180356172329712%2F&show_text=1&width=560" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>
            </div>
        </div>
      </div>
    </Layout>
  )
}

export default NewsPage
